import Vue from "vue"
import DatePicker from 'vue2-datepicker'
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    DatePicker,
    Paginate
  },
  data: function() {
    return {
      busy: false,
      date_start: null,
      date_end: null,
      perPage: 6,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      totalItem: 0,
      apiStatus: 'ไม่พบข้อมูล',
      fields: [
        { 
          key: 'doc_no', 
          label: 'เลขที่เอกสาร', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'doc_date', 
          label: 'วันที่เอกสาร', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'doc_ref', 
          label: 'เอกสารอ้างอิง', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'inv_no', 
          label: 'เลขที่ใบกำกับภาษี', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'receipt_no', 
          label: 'เลขที่ใบเสร็จ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'cus_code', 
          label: 'รหัสลูกค้า',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'cus_name', 
          label: 'ชื่อลูกค้า',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'emp', 
          label: 'พนักงาน',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'before_vat', 
          label: 'ก่อนภาษี',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right',
          sortable: true 
        },
        { 
          key: 'vat', 
          label: 'ภาษี', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right' 
        },
        { 
          key: 'nettotal', 
          label: 'ยอดรวมสุทธิ', 
          sortable: true,
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right' 
        }
      ],
      items: [
        {
          _id: '123456',
          doc_no: 'AR-201665156',
          doc_date: new Date(),
          doc_ref: '',
          receipt_no: '',
          inv_no: 'INV-202215615',
          cus_code: 'C-001',
          cus_name: 'สมชาย',
          emp: 'กมชนก',
          nettotal: 2500
        }
      ]
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    onInitData() {
      try {
        const item = this.items
        this.items = []
        this.busy = true
        this.apiStatus = 'กำลังค้นหาข้อมูล..'
        
        setTimeout(() => {
          this.busy = false
          this.apiStatus = 'ไม่พบข้อมูล'
          
          this.items = item
        }, 1000)
      } catch (error) {
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        this.onExceptionHandler(error.response.data.message);
      }
    }
  }
}